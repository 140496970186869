<template>
    <div>
        <InfoCard>
            <template v-if="!onMobileView">
                <div
                    class="py-3 cursor-pointer application"
                    v-for="(n, i) in 8"
                    :key="i"
                    :class="{
                        'bb-1': i !== 7,
                        active: selectedApplication === i,
                    }"
                    @click="selectedApplication = i"
                >
                    <div class="mb-3">
                        <p class="fs-18 fw-600 text__mid-back mb-1x">
                            Business Development Manager
                        </p>
                        <p class="fs-14 fw-400 text__grey">
                            Google India Private Limited
                        </p>
                    </div>

                    <div class="flex flex__spaced">
                        <div class="flex flex__item-center">
                            <div class="flex">
                                <img
                                    src="../../../../../assets/icons/pin-icon.svg"
                                    alt="pin-icon"
                                />
                            </div>
                            &nbsp;
                            <span class="fw-600 text__dark-grey fs-14">
                                Ibadan & Oyo State
                            </span>
                        </div>
                        <p class="text__light-blue fs-12">
                            Recruiter last online 24 mins ago
                        </p>
                    </div>
                </div>
            </template>

            <template v-else>
                <MobileView />
            </template>
        </InfoCard>
    </div>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import MediaQuery from "@/mixins/mediaQuery";
import MobileView from "./components/MobileView";

export default {
    name: "MyApplications",
    mixins: [MediaQuery],
    components: {
        InfoCard,
        MobileView,
    },
    data() {
        return {
            selectedApplication: "",
        };
    },
};
</script>

<style scoped lang="scss">
.mb-1x {
    margin-bottom: 4px;
}

.application {
    transition: 0.3s all ease-out;

    &.active {
        background: lighten(#1aa7ec, 45%);
        padding: 0 10px;
    }
}
</style>
