<template>
    <div>
        <div class="py-3 bt-1">
            <div class="flex flex__spaced mb-2">
                <div>
                    <p class="fs-18 fw-600 text__mid-back mb-1x">
                        Business Development Manager
                    </p>
                    <p class="fs-14 fw-400 text__grey">
                        Google India Private Limited
                    </p>
                </div>
                <Button theme="blue" class="w-15">View Similar Jobs</Button>
            </div>

            <div class="flex flex__spaced">
                <div class="flex flex__item-center">
                    <div class="flex">
                        <img
                            src="../../../../../assets/icons/pin-icon.svg"
                            alt="pin-icon"
                        />
                    </div>
                    &nbsp;
                    <span class="fw-600 text__dark-grey fs-14">
                        Ibadan & Oyo State
                    </span>
                </div>
                <p class="text__light-blue fs-12">
                    Recruiter last online 24 mins ago
                </p>
            </div>
        </div>

        <div class="py-3 bt-1">
            <p class="fs-18 fw-600 text__grey mb-3">Application Status</p>

            <div
                class="flex onboarding__tab-wrapper p-0 flex__start"
                id="tab-wrapper"
            >
                <div class="onboarding__tab flex flex__item-center active">
                    <div
                        class="flex flex__column flex__item-center onboarding__tab-content"
                    >
                        <div
                            class="onboarding__tab-icon-wrap solid mb-1 flex flex__center flex__item-center"
                        >
                            <div class="onboarding__tab-icon">
                                <img
                                    src="../../../../../assets/icons/check-blue-circle-icon.svg"
                                    alt="check-blue-icon"
                                />
                            </div>
                        </div>
                        <div class="mb-1x text-center">
                            <p class="text__dark-grey fs-14 fw-400">
                                Applied for the Job
                            </p>
                            <p class="text__light-grey fs-12">
                                05 Jun'20 12:45 PM
                            </p>
                        </div>
                    </div>
                    <div class="onboarding__tab-spacer">
                        <div class="w-10 item solid" />
                    </div>
                </div>
                <div class="onboarding__tab flex flex__item-center">
                    <div
                        class="flex flex__column flex__item-center onboarding__tab-content"
                    >
                        <div
                            class="onboarding__tab-icon-wrap solid mb-1 flex flex__center flex__item-center"
                        >
                            <div class="onboarding__tab-icon">
                                <img
                                    src="../../../../../assets/icons/check-blue-circle-icon.svg"
                                    alt="check-blue-icon"
                                />
                            </div>
                        </div>
                        <div class="mb-1x text-center">
                            <p class="text__dark-grey fs-14 fw-400">
                                Recruiter viewed on
                            </p>
                            <p class="text__light-grey fs-12">
                                10 Jun'20 12:45 PM
                            </p>
                        </div>
                    </div>
                    <div class="onboarding__tab-spacer">
                        <div class="w-10 item solid" />
                    </div>
                </div>
                <div class="onboarding__tab flex flex__item-center">
                    <div
                        class="flex flex__column flex__item-center onboarding__tab-content"
                    >
                        <div
                            class="onboarding__tab-icon-wrap solid mb-1 flex flex__center flex__item-center"
                        >
                            <div class="onboarding__tab-icon">
                                <img
                                    src="../../../../../assets/icons/check-blue-circle-icon.svg"
                                    alt="check-blue-icon"
                                />
                            </div>
                        </div>
                        <div class="mb-1x text-center">
                            <p class="text__light-grey fs-14 fw-400">
                                Shortlisted on
                            </p>
                            <p class="text__light-grey fs-12">Awaiting</p>
                        </div>
                    </div>
                    <div class="onboarding__tab-spacer">
                        <div class="w-10 item solid" />
                    </div>
                </div>
            </div>
        </div>

        <div class="py-3 bt-1">
            <p class="fs-18 fw-600 text__grey mb-3">Activity on this Job</p>

            <div class="flex flex__col-gap-5">
                <div class="pl-2">
                    <p class="text__dark-grey fs-24 fw-600 mb-1">204</p>
                    <p class="fs-12 text__grey fw-400">Total Applications</p>
                </div>
                <div class="pl-2">
                    <p class="text__dark-grey fs-24 fw-600 mb-1">150</p>
                    <p class="fs-12 text__grey fw-400">Applications Viewed</p>
                </div>
                <div class="pl-2">
                    <p class="text__dark-grey fs-24 fw-600 mb-1">20</p>
                    <p class="fs-12 text__grey fw-400">
                        Candidates Shortlisted
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ApplicationStatus",
    data() {
        return {
            currentStep: 1,
            statusSteps: ["Profile Summary", "Education", "Experience"],
        };
    },
};
</script>

<style scoped lang="scss">
.mb-1x {
    margin-bottom: 4px;
}

.status__info {
    width: 284px;
    background: #fafdff;
    border-radius: 4px;
}
</style>
