<template>
  <PageLayout :headerWithBG="true">
    <main>
      <div class="flex flex__col-gap-1 mb-4 m-flex__spaced">
        <div class="app__card">
          <p class="text__dark-grey mb-1x fs-24 fw-600">25</p>
          <p class="text__grey fs-12">Applications</p>
        </div>
        <div class="app__card">
          <p class="text__dark-grey mb-1x fs-24 fw-600">05</p>
          <p class="text__grey fs-12">Shortlisted</p>
        </div>
        <div class="app__card wide">
          <p class="text__dark-grey mb-1x fs-24 fw-600">15</p>
          <p class="text__grey fs-12">Not Seen Yet</p>
        </div>
      </div>

      <div
        class="grid grid__layout gap-4 m-gap-0 flex__item-center mb-2 grid__mobile"
      >
        <div class="col-5 m-col-12">
          <p class="fs-24 fw-600 text__dark-grey">My Applications</p>
        </div>
        <div class="col-7 m-d-none">
          <div class="flex flex__spaced flex__item-center">
            <p class="fs-24 fw-600 text__dark-grey">Job Application Status</p>
            <div class="flex flex__col-gap-2 flex__item-center">
              <div class="p-2 flex flex__item-center status__info">
                <div class="flex mr-2">
                  <img
                    src="../../../assets/icons/info-icon.svg"
                    alt="info-icon"
                  />
                </div>
                <p class="flex__grow text__dark-grey lh-24">
                  Not getting views on your CV ? Keep your profile updated
                </p>
              </div>

              <Button
                @click="
                  $router.push({
                    name: 'account',
                    query: { tab: 'Resume' }
                  })
                "
                theme="blue"
                class="w-13"
                >Update Profile</Button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid__layout gap-4 grid__mobile m-gap-0">
        <div class="col-5 m-col-12">
          <MyApplications />
        </div>
        <div class="col-7 m-d-none">
          <ApplicationStatus />
        </div>
      </div>
    </main>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import MyApplications from "./components/MyApplications";
import ApplicationStatus from "./components/ApplicationStatus";

export default {
  name: "Applications",
  components: {
    PageLayout,
    MyApplications,
    ApplicationStatus
  }
};
</script>

<style scoped lang="scss">
@use "../../../assets/scss/mixins/index" as *;

main {
  padding: 48px 80px 70px;

  @include query("mobile") {
    padding: 32px 16px;
  }
}

.mb-1x {
  margin-bottom: 8px;
}

.app {
  &__card {
    padding: 20px;
    width: 160px;
    background: #fafdff;
    border: 1px solid #f0f1f3;
    border-radius: 8px;

    &.wide {
      width: 200px;
    }
    @include query("mobile") {
      width: 33.3% !important;
    }
  }
}

.status__info {
  width: 284px;
  background: #fafdff;
  border-radius: 4px;
}
</style>
