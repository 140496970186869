<template>
    <div>
        <div
            class="pb-3"
            @click="showAccordion = !showAccordion"
            :class="{ 'bb-1': showAccordion }"
        >
            <div class="flex flex__spaced flex__item-center mb-3">
                <div>
                    <p class="fs-18 fw-600 text__mid-back mb-1x">
                        Business Development Manager
                    </p>
                    <p class="fs-14 fw-400 text__grey">
                        Google India Private Limited
                    </p>
                </div>
                <div
                    class="accordion__arrow"
                    :class="{ active: showAccordion }"
                >
                    <img src="@/assets/icons/down-arrow.svg" alt="down-arrow" />
                </div>
            </div>

            <div class="flex flex__spaced flex__item-center">
                <div class="flex flex__item-center">
                    <div class="flex">
                        <img
                            src="../../../../../../../assets/icons/pin-icon.svg"
                            alt="pin-icon"
                        />
                    </div>
                    &nbsp;
                    <span class="fw-600 text__grey fs-14">
                        Ibadan & Oyo State
                    </span>
                </div>
                <p class="text__light-blue fs-12">
                    Recruiter last online 24 mins ago
                </p>
            </div>
        </div>

        <div class="accordion__view" :class="{ active: showAccordion }">
            <h2 class="text__grey mb-3">Application Status</h2>

            <div class="application__step-wrapper pb-3 bb-1">
                <div class="flex flex__column application__step active">
                    <div class="flex flex__col-gap-1 flex__item-center">
                        <div class="onboarding__tab-icon">
                            <img
                                src="../../../../../../../assets/icons/check-blue-circle-icon.svg"
                                alt="check-blue-icon"
                            />
                        </div>
                        <div>
                            <p class="text__dark-grey fs-14 fw-400 mb-1x">
                                Applied for the Job
                            </p>
                            <p class="text__light-grey fs-12">
                                05 Jun'20 12:45 PM
                            </p>
                        </div>
                    </div>
                    <div class="my-2 application__step-spacer"></div>
                </div>
                <div class="flex flex__column application__step">
                    <div class="flex flex__col-gap-1 flex__item-center">
                        <div class="onboarding__tab-icon">
                            <img
                                src="../../../../../../../assets/icons/check-blue-circle-icon.svg"
                                alt="check-blue-icon"
                            />
                        </div>
                        <div>
                            <p class="text__dark-grey fs-14 fw-400 mb-1x">
                                Recruiter viewed on
                            </p>
                            <p class="text__light-grey fs-12">
                                10 Jun'20 12:45 PM
                            </p>
                        </div>
                    </div>
                    <div class="my-2 application__step-spacer"></div>
                </div>
                <div class="flex flex__column application__step">
                    <div class="flex flex__col-gap-1 flex__item-center">
                        <div class="onboarding__tab-icon">
                            <img
                                src="../../../../../../../assets/icons/check-blue-circle-icon.svg"
                                alt="check-blue-icon"
                            />
                        </div>
                        <div>
                            <p class="text__light-grey fs-14 fw-400 mb-1x">
                                Shortlisted on
                            </p>
                            <p class="text__light-grey fs-12">Awaiting</p>
                        </div>
                    </div>
                    <div class="my-2 application__step-spacer"></div>
                </div>
            </div>

            <div class="py-3 bt-1">
                <p class="fs-18 fw-600 text__grey mb-3">Activity on this Job</p>

                <div class="flex flex__col-gap-2 pb-3 bb-1">
                    <div class="pl-2">
                        <p class="text__dark-grey fs-24 fw-600 mb-1">204</p>
                        <p class="fs-12 text__grey fw-400">
                            Total Applications
                        </p>
                    </div>
                    <div class="pl-2">
                        <p class="text__dark-grey fs-24 fw-600 mb-1">150</p>
                        <p class="fs-12 text__grey fw-400">
                            Applications Viewed
                        </p>
                    </div>
                    <div class="pl-2">
                        <p class="text__dark-grey fs-24 fw-600 mb-1">20</p>
                        <p class="fs-12 text__grey fw-400">
                            Candidates Shortlisted
                        </p>
                    </div>
                </div>
            </div>

            <Button theme="blue"> View Similar Jobs </Button>

            <div class="pt-4 pb-2">
                <div class="onboarding__tab-icon">
                    <img
                        src="../../../../../../../assets/icons/info-icon.svg"
                        alt="info-icon"
                    />
                </div>
                <p class="text__dark-grey fs-12">
                    Not getting views on your CV? Keep your profile updated
                </p>
            </div>

            <Button
                @click="$router.push({ name: 'profile' })"
                theme="blue update-btn"
                >Update Profile</Button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileView",
    data() {
        return {
            showAccordion: false,
        };
    },
};
</script>

<style scoped lang="scss">
@use "./index" as *;
</style>
